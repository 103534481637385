import React, { useEffect } from "react";
import { helmetJsonLdProp } from "react-schemaorg";

import { Container, Row, Col } from "react-bootstrap/esm/index";
import Helmet from "react-helmet";
import {
  pushOutboundClick
} from "../../assets/datalayerFunctions";

// Import Components
import ModalLinkButton from "../../components/shared-ui/Buttons/ModalLinkButtons";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";

import ResourceSection from "../../components/PatientResources/ResourceSection";
import Resource from "../../components/PatientResources/Resource";
import ShareYourStory from "../../components/PatientResources/ShareYourStory";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

// Import Styles
import "../../css/PatientResources.scss";

function showModal(url) {
  let modal = document.querySelector("#modal");
  document.body.classList.add("no-scroll", "modal-open");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100%";
  modal.children[0].children[1].children[3].href = url;
  modal.children[0].children[1].children[3].target = "_blank";
  modal.children[0].children[1].children[3].addEventListener(
    "click",
    function() {
      modal.style.display = "none";
    }
  );
}

function PatientResources() {
  function addModalLinks(event) {
    pushOutboundClick(event);
    event.preventDefault();
    let url = event.currentTarget.href;
    let title = event.currentTarget.title;
    showModal(url, title);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "ITP Patient Resources | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/itp-lifestyle-resources/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
              {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                      "@id": "https://doptelet.com/support-and-resources/itp-patient-resources/",
                      "Name": "Find ITP Resources"
                    }
                  }
                ]
              },
            ],
          }),
        ]}
      >
        <title>Chronic ITP Patient Resources | DOPTELET® (avatrombopag)</title>
        <meta
          name="description"
          content="Access patient resources to help you navigate ITP, like downloadable guides, copay assistance enrollment, tips for talking to your doctor & more."
        />
        <link rel="canonical" href="https://doptelet.com/support-and-resources/itp-patient-resources/" />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap patient-resources">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          markup={
            <>
              <h1>CHRONIC ITP RESOURCES</h1>
              <p className="mb-0">Let&lsquo;s explore the different support options that are available during treatment for your low platelet counts in chronic immune thrombocytopenia (ITP).</p>
            </>
          }
          maxWidth="513"
          bgImage={['chronic-itp-resources-hero.jpg', 'chronic-itp-resources-hero.jpg']}
          bgImageMobile={['chronic-itp-resources-hero-mobile.jpg', 'chronic-itp-resources-hero-mobile@2x.jpg']}
        />

        <section className="pt-5 pb-0 py-md-5">
          <Container>
            <h2 className="mb-4">Doptelet Patient Resources</h2>
            <Row className="mb-4">
              <Col md={12} lg={{ span: 6 }} className="mb-4 mb-lg-0">
                <div className="cloud-blue-bg p-4 d-flex flex-column h-100">
                  <h4 className="text-orange"><strong>Considering Doptelet?</strong></h4>
                  <p className="flex-grow-1">Get a quick overview of Doptelet benefits before talking to your doctor about whether it may be the right fit for you.</p>
                  <PrimaryButton
                    title="DOWNLOAD TRIFOLD"
                    href="/pdf/Patient-Trifold.pdf"
                    addClass="btn-transparent download align-self-baseline"
                    target="_blank"
                  />
                </div>
              </Col>
              <Col md={12} lg={6}>
                <div className="cloud-blue-bg p-4 d-flex flex-column h-100">
                  <h4 className="text-orange"><strong>Starting Doptelet?</strong></h4>
                  <p className="flex-grow-1">Read more about chronic ITP and its treatment with Doptelet after talking to your doctor.</p>
                  <PrimaryButton
                    title="DOWNLOAD BROCHURE"
                    href="/pdf/Patient-Brochure.pdf"
                    addClass="btn-transparent download align-self-baseline"
                    target="_blank"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={12} lg={{ span: 6 }} className="mb-4 mb-lg-0">
                <div className="cloud-blue-bg p-4 d-flex flex-column h-100">
                  <h4 className="text-orange"><strong>Talking to your doctor soon?</strong></h4>
                  <p className="flex-grow-1">Living with chronic ITP is hard, but talking about it shouldn’t be. Your voice matters when it comes to discussing treatment, and we’re here to help you find it.</p>
                  <PrimaryButton
                    title="DOWNLOAD BROCHURE"
                    href="/pdf/Dr-Discussion-Guide.pdf"
                    addClass="btn-transparent download align-self-baseline"
                    target="_blank"
                  />
                </div>
              </Col>
              <Col md={12} lg={6}>
                <div className="cloud-blue-bg p-4 d-flex flex-column h-100">
                  <h4 className="text-orange"><strong>Trying to enroll for copay support?</strong></h4>
                  <p className="flex-grow-1">Enroll yourself in Doptelet Connect today.</p>
                  <PrimaryButton
                    title="DOWNLOAD FORM"
                    href="/pdf/doptelet-prescription-enrollment-form.pdf"
                    addClass="btn-transparent download align-self-baseline"
                    target="_blank"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container className="swings">
            <Row>
              <Col lg={{ span: 12 }} className="d-flex justify-content-center border-bottom">
                <img src="/images/swings_transparent.png" className="img-responsive" style={{ maxWidth: '450px' }} />
              </Col>
            </Row>
          </Container>
        </section>

        <div className="pt-5">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 12 }}>
                <h2 className="mb-4">KEEP YOUR ITP JOURNEY ON TRACK</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row className="align-items-center mx-0">
            <Col lg={{ span: 12 }} className="cloud-blue-bg p-5 florio-section">
              <div className="d-flex">
                <img src="/images/MyFlorioLogo.jpg" />
                <div>
                  <h2>my florio ITP&trade;</h2>
                  <p>my florio ITP&trade; is an all-in-one app designed to monitor all things ITP—platelet counts, bruising events, appointments, treatments, and more—plus, it’s free!</p>
                  <p><a href="https://apps.apple.com/no/app/florio-itp/id1610121401" target="_blank" onClick={addModalLinks}><img src="/images/app-store.png" /></a> <a href="https://play.google.com/store/apps/details?id=app.florio.itp.eu.mdd&pli=1" target="_blank" onClick={addModalLinks}><img src="/images/google-play.png" /></a></p>
                  <p><a href="http://doptelet.com/themes/pdf/florio-itp-app-one-pager.pdf" className="download-link" target="_blank"><img src="/images/icon_download.svg" /> DOWNLOAD BROCHURE</a></p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <section className="pt-5">
          <Container>
            <Row className="mb-4 align-items-center flex-column-reverse flex-lg-row">
              <Col md={12} lg={{ span: 6 }}>
                <h2>Got Questions? Speak to a Doptelet Advisor</h2>
                <p>Trying to raise your low platelet count can raise some questions. The good news is that our Doptelet Advisors can help you get the answers you need and point you towards resources. Give them a call at <a href="tel:1-855-454-3887" className="no-break"><strong>855-4-LIFTUP</strong></a> <span className="no-break"><a href="tel:1-855-454-3887">(1-855-454-3887)</a>.</span></p>
              </Col>
              <Col md={12} lg={{ span: 4, offset: 1 }}>
                <img className="img-responsive" src="/images/Platey_Orange_Balloon.png" />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pt-5 pb-0 pb-md-6">
          <Container className="border-top pt-5">
            <Row className="mb-4 align-items-center">
              <Col md={12} lg={{ span: 4, offset: 1 }}>
                <img className="img-responsive" src="/images/soft-serve.png" />
              </Col>
              <Col md={12} lg={{ span: 7 }}>
                <h2>Become Part of a Chronic ITP Community</h2>
                <p>If you&lsquo;re looking to meet others living with platelet conditions like chronic immune thrombocytopenia, these organizations are good places to start. They exist to create a community of support and information for people like you.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <Container id="organizations" className="px-xl-0">
          <Row className="mb-5 align-items-center">
            <Col lg={12}>
              <Row>
                <Col md={12} lg={4} className="d-flex flex-column mb-5 mb-lg-0">
                  <div class="image-wrapper">
                    <img
                      src="/images/platelet-disorder-support-assocation-pdsa-logo-empowering-itp-patients.png"
                      alt="Platelet Disorder Support Association (PDSA) - Empowering ITP Patients Logo"
                      className="mb-4" />
                  </div>
                  <h3 className="pr-xl-3">Platelet Disorder Support Association (PDSA)</h3>
                  <p className="flex-grow-1">A non-profit organization founded in 1998 to enhance the lives of patients with ITP and other platelet disorders through education, advocacy, research, and support.</p>
                  <ModalLinkButton
                    title="Visit PDSA"
                    href="https://www.pdsa.org"
                    onClick={addModalLinks}
                    target="_blank"
                    rel="noopener noreferrer"
                    addClass="modalLink btn-transparent btn-external-link" />
                </Col>
                <Col md={12} lg={4} className="d-flex flex-column mb-5 mb-lg-0">
                  <div class="image-wrapper">
                    <img
                      src="/images/foundation-for-women-and-girls-blood-disorders-logo.png"
                      alt="Foundation for Women & Girls with Blood Disorders Logo"
                      className="mb-4" />
                  </div>
                  <h3>Foundation for Women & Girls with Blood Disorders (FWGBD)</h3>
                  <p className="flex-grow-1">An organization that helps to ensure that all women with blood disorders are correctly diagnosed and treated at every stage of life.</p>
                  <ModalLinkButton
                    title="VISIT FWGBD"
                    href="https://www.fwgbd.org/patient"
                    target="_blank"
                    addClass="modalLink btn-transparent btn-external-link"
                    onClick={addModalLinks} />
                </Col>
                <Col md={12} lg={4} className="d-flex flex-column mb-0 mb-lg-0">
                  <div class="image-wrapper">
                    <img
                      src="/images/national-organization-for-rare-disorders-nord-logo.png"
                      alt="National Organization for Rare Disorders (NORD) Logo"
                      className="mb-4" />
                  </div>
                  <h3>National Organization for Rare Disorders (NORD)</h3>
                  <p className="flex-grow-1">A non-profit organization for people living with rare diseases. NORD is committed to the identification, treatment, and cure of rare diseases.</p>
                  <ModalLinkButton
                    title="VISIT NORD"
                    href="https://rarediseases.org/rare-diseases/immune-thrombocytopenia/"
                    target="_blank"
                    addClass="modalLink btn-transparent btn-external-link"
                    onClick={addModalLinks} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="mb-4 align-items-center">
            <Col lg={12} className="border-bottom"></Col>
          </Row>
        </Container>

        <div className="mt-5 bottom-page-ctas">
          <Container fluid>
            <Row>
              <Col md={6} className="d-flex flex-column">
                <div className="content">
                  <picture className="text-center mb-3">
                    <source
                      srcSet="/images/Platey_Ryder_Bubbles.png 1x, /images/Platey_Ryder_Bubbles@2x.png 2x"
                      type="image/png" />
                    <img src="/images/Platey_Ryder_Bubbles.png" />
                  </picture>
                  <h2>Do you want updates on tips for managing chronic ITP?</h2>
                  <p className="flex-grow-1">Sign up to access tips and tools for managing ITP&ndash;plus, a surprise shipped to your doorstep!</p>
                  <PrimaryButton
                    title="GET CHRONIC ITP TIPS"
                    href="/support-and-resources/living-with-itp/"
                    addClass="btn-transparent btn-arrow align-self-start"
                  />
                </div>
              </Col>
              <Col md={6} className="cloud-blue-bg d-flex flex-column">
                <div className="content">
                  <picture className="text-center mb-3">
                    <source
                      srcSet="/images/circle_tammy.png 1x, /images/circle_tammy.png 2x"
                      type="image/png" />
                    <img src="/images/circle_tammy.png" />
                  </picture>
                  <h2>Want to hear from a real person on Doptelet?</h2>
                  <p className="flex-grow-1 pr-xl-5">Tammy, a patient living with chronic ITP, talks about her experience on Doptelet.</p>
                  <PrimaryButton
                    title="ITP VIDEO RESOURCES"
                    href="/support-and-resources/chronic-itp-videos/"
                    addClass="btn-arrow align-self-start"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>


        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default PatientResources;
