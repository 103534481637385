import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import { helmetJsonLdProp } from "react-schemaorg";

// Import Components
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import CLDNavigation from "../../components/CLDNavigation/CLDNavigation";
import ISICLDinline from "../../components/shared-ui/ISI/ISICLD-inline";
import FooterCLD from "../../components/shared-ui/Footer/Footer-CLD";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISICLD from "../../components/shared-ui/ISI/ISICLD";
import SpeakerButton from "../../components/shared-ui/Buttons/SpeakerButton";
import { pushContactClick } from "../../assets/datalayerFunctions";

//CSS
import "../../css/CLDHome.scss";

function showTermsModal(event) {
  let modal = document.getElementById("terms-modal");
  document.body.classList.add("no-scroll");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100vh";
  if (event.currentTarget.classList.contains("scroll-to-terms")) {
    const terms = document.getElementById("terms");
    const container = document.querySelector("#terms-modal .modal-container");
    container.scroll(0, terms.offsetTop);
  }
}

function CLDHome() {
  useEffect(() => {
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Chronic Liver Disease (CLD) | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "CLD",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap CLDHome">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/cld/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough.Patients long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName:
                    "Immune Thrombocytopenia (ITP) and Chronic Liver Disease (CLD)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "MedicalTherapy",
                seriousAdverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "DOPTELET may cause serious side effects, including blood clots. People with chronic liver disease and people with certain blood clotting conditions may have an increased risk of developing blood clots. Tell your healthcare provider right away if you have signs and symptoms of a blood clot, including: Swelling, pain, or tenderness in your legs, Shortness of breath, Chest pain, Fast heartbeat, and Stomach (abdominal) pain or tenderness",
                },
              },
              {
                "@type": "MedicalTherapy",
                adverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "The most common side effects of DOPTELET in people with chronic liver disease are: fever, stomach (abdominal) pain, nausea, headache, tiredness, and swelling of the hands or feet",
                },
              },

              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET in people with chronic liver disease are: fever, stomach (abdominal) pain, nausea, headache, tiredness, swelling of the hands or feet",
              },
              {
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 2,
                    item: {
                      "@id": "https://doptelet.com/cld/",
                      Name: "cld",
                    },
                  },
                ],
              },
            ],
          }),
        ]}
      >
        <title>Doptelet for Thrombocytopenia in Adult CLD Patients | DOPTELET® (avatrombopag)
        </title>
        <meta
          name="description"
          content="Learn about DOPTELET®, a treatment that increases platelets for adult patients with Chronic Liver Disease (CLD) who are scheduled to undergo a procedure."
        />
        <link rel="canonical" href="https://doptelet.com/cld/" />
      </Helmet>
      <CLDNavigation />
      <div className="inner-content-wrap">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          textSitsOnImageForMobile
          markup={
            <>
              <p>
                <strong>
                  For adults with chronic liver disease (CLD) who have low
                  platelet counts and are preparing for a procedure
                </strong>
              </p>
              <h1 style={{ maxWidth: 300 }}>Moving Forward<br></br> with Doptelet</h1>
              <div className="speaker-btn-wrapper">
                <h3 className="red light-font mb-0">
                  Say it with me:{" "}
                  <strong className="no-break-mobile">
                    "dop&#45;TELL&#45;it"
                  </strong>
                  <SpeakerButton />
                </h3>
              </div>
            </>
          }
          maxWidth="500"
          bgImage={["managing-itp-hero.jpg", "managing-itp-hero.jpg"]}
          bgImageMobile={[
            "cld-home-hero-mobile.jpg", "cld-home-hero-mobile.jpg"
          ]}
        />

        <section id="about-doptelet">
          <Container>
            <Row className="d-flex">
              <Col md={6}>
                <h2 className="mb-4">
                  Doptelet is a pill that lifts platelet counts with just 5 daily doses.
                </h2>

                <p className="copy">
                  If you have CLD, your doctor may have prescribed Doptelet to give your platelet counts a lift to reduce the risk of bleeding before your medical procedure.
                </p>

                <p className="p-footnote">
                  <strong>
                    Some common side effects of Doptelet (avatrombopag) are fever, headache, and stomach pain. See important safety info below for the full list.
                  </strong>
                </p>
              </Col>
              <Col md={6}>
                <div className="img-wrapper">
                  <img
                    src="/images/circus-games.jpg"
                    alt="Doptelet Platelet Characters Playing Circus Games"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="taking-doptelet-cld">
          <Container>
            <div className="how-to">
              <h2>
                How do I take Doptelet?
              </h2>
              <p className="mb-4">
                Start taking Doptelet 10 to 13 days prior to your procedure. You
                and your doctor will work together to decide when your first
                dose will be. Someone from your care team may contact you as
                your start date approaches, just to{" "}
                <span className="no-break-415">remind you.</span>
              </p>
              <div className="d-flex flex-column align-items-center circle-wrapper">
                <p className="circle-header size-18">Take Doptelet</p>
                <ul>
                  <li className="text-center">
                    <div className="img-wrapper">
                      <img
                        src="/images/icon_clock.svg"
                        alt="Once A Day Clock Icon"
                      />
                    </div>
                    <p>Once a day</p>
                  </li>
                  <li className="text-center">
                    <div className="img-wrapper">
                      <img
                        src="/images/five-tally-marks-icon.svg"
                        alt="Five Days In A Row Tally Marks Icon"
                      />
                    </div>
                    <p>For five days in a row</p>
                  </li>
                  <li className="text-center">
                    <div className="img-wrapper">
                      <img
                        src="/images/icon_no_food_restrictions.svg"
                        alt="Any Food You Like Pizza Icon"
                      />
                    </div>
                    <p>With any food you like</p>
                  </li>
                </ul>
              </div>
              <div>
                <p className="fs-16-md">
                  In case of a missed dose, take the next dose of Doptelet as
                  soon as you remember. Don’t take two at once to make up for a
                  missed dose, just take the next one at the usual time the next
                  day: all 5 days of dosing should be completed.
                </p>
              </div>
            </div>
          </Container>
        </section>

        <section id="dosing-guide" className="dark-blue-bg mb-md-0">
          <Container>
            <Row>
              <Col>
                <h2 className="mb-3">It’s important to take Doptelet exactly as your doctor prescribed.</h2>
                <p>For more information, take a look at the Doptelet Dosing Guide.</p>
                <a
                  href="/themes/pdf/cld-patient-dosing-guide.pdf"
                  target="_blank"
                  className="btn btn-primary download"
                >
                  <span>DOPTELET DOSING GUIDE</span>
                </a>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="got-questions">
          <Container>
            <div className="got-questions">
              <h2 className="text-blue">
                Got questions?{" "}
                <span className="no-break-mobile">855&#45;4&#45;LIFTUP</span>
              </h2>
              <p>
                If you're in need of answers, we've got them! Our Doptelet
                Advisors can help you navigate your 5 days of Doptelet&mdash;just
                call{" "}
                <a
                  onClick={pushContactClick}
                  href="tel:+18554543887"
                  className="no-break-desktop-mobile"
                >
                  <strong className="text-underline">
                    855&#45;4&#45;LIFTUP
                  </strong>{" "}
                  (
                  <strong className="text-underline">
                    1&#45;855&#45;454&#45;3887
                  </strong>
                  )
                </a>
                .
              </p>
            </div>
          </Container>
        </section>

        <section id="getting-doptelet" className="doptelet-connect-container">
          <Container>
            <Row>
              <Col md={{ span: 12, offset: 0 }}>
                <img src="/images/DopteletConnect_Logo@2x.png" />
                <h2 className="mt-3 mb-2">
                  How can I get Doptelet?
                </h2>
                <p>
                  Doptelet Connect is one of the ways to help you access treatment. Doptelet Connect can work with you and your doctor to find a pharmacy and get your medication. These steps describe how Doptelet Connect works:
                </p>
                <div className="step d-flex align-items-center">
                  <div className="number-and-icon d-flex justify-content-center">
                    <span className="text-red">1</span>
                    <img src="/images/icon-prescription.svg" />
                  </div>
                  <div className="copy">
                    <p>
                      <strong>
                        Your doctor sends your prescription to Doptelet Connect
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="step step-2 d-flex flex-column flex-md-row align-items-start">
                  <div className="number-and-icon d-flex justify-content-center">
                    <span className="text-red">2</span>
                    <img src="/images/icon-representative.svg" />
                    <p className="d-md-none"><strong>Dedicated Care Coordinators can help:</strong></p>
                  </div>
                  <div className="copy mt-3 mt-md-0">
                    <p className="d-none d-md-block"><strong>Dedicated Care Coordinators can help:</strong></p>
                    <ul>
                      <li className="mb-3">
                        <strong>Research</strong> your individual coverage for Doptelet
                      </li>
                      <li className="mb-3">
                        <strong>Coordinate</strong> with an eligible pharmacy to fill your prescription
                      </li>
                      <li>
                        <strong>Connect</strong> with you to identify financial assistance options, if needed. You must meet{" "}
                        <a
                          href="#discover"
                          onClick={showTermsModal}
                          className="text-underline"
                        ><strong>eligibility criteria</strong></a>.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="step d-flex align-items-center">
                  <div className="number-and-icon d-flex justify-content-center">
                    <span className="text-red">3</span>
                    <img src="/images/icon-pharmacy.svg" />
                  </div>
                  <div className="copy">
                    <p>
                      <strong>
                        A pharmacy will contact you to confirm your delivery details before sending Doptelet to you
                      </strong>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="patient-resources" className="">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <h2 className="mb-0">
                  Doptelet Connect Patient Resources
                </h2>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="doptelet-connect-resource">
          <Container>
            <Row>
              <Col md={{ span: 6 }} className="mb-4 mb-md-0">
                <div className="blue-bg">
                  <div className="d-flex align-items-center mb-4 mb-md-5 w-100">
                    <img
                      src="/images/icon_prescription.svg"
                      alt="Prescription pad Icon"
                      className="mr-4"
                    />
                    <strong>Enrollment and Prescription Form</strong>
                  </div>
                  <p>
                    Provides the required information to enroll in Doptelet Connect
                  </p>
                  <p className="mt-3 mb-0">
                    <a
                      href="/pdf/doptelet-prescription-enrollment-form.pdf"
                      target="_blank"
                      className="btn btn-primary btn-transparent download"
                    ><span>
                        DOWNLOAD PDF</span>
                    </a>
                  </p>
                </div>
              </Col>
              <Col md={{ span: 6 }}>
                <div className="blue-bg">
                  <div className="d-flex align-items-center mb-4 mb-md-5 w-100">
                    <img
                      src="/images/icon_prescription.svg"
                      alt="Prescription pad Icon"
                      className="mr-4"
                    />
                    <strong>Patient/Caregiver Consent Form</strong>
                  </div>
                  <p>Facilitates patient/caregiver authorization to enroll in Doptelet Connect</p>
                  <p className="mt-3 mb-0">
                    <a
                      href="/pdf/patient-caregiver-enrollment-consent-form.pdf"
                      target="_blank"
                      className="btn btn-primary btn-transparent download"
                    ><span>
                        DOWNLOAD PDF</span>
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="copay-program" className="cloud-blue-bg">
          <Container>
            <Row className="d-flex align-items-center">
              <Col md={{ span: 8, offset: 1 }}>
                <h2>Doptelet Copay Assistance Program</h2>
                <p className="mt-3">
                  Eligible patients who have commercial insurance may pay as
                  little as $0.
                  <br className="d-none d-md-block" /> See{" "}
                  <a
                    href="#terms"
                    onClick={showTermsModal}
                    className="text-underline"
                  >
                    terms and conditions
                  </a>
                  .
                </p>
                <p>
                  <a
                    href="https://www.activatethecard.com/8015/?src=patient"
                    className="btn btn-primary btn-arrow mt-0"
                    target="_blank"
                  >
                    Enroll now
                  </a>
                </p>
              </Col>
              <Col md={3} className="pr-md-5">
                <div className="img-wrapper">
                  <img
                    src="/images/pay_as_little_2024.svg"
                    alt='"Pay as Little as $0" Doptelet Copay Icon'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="financial-assistance">
          <Container>
            <Row className="d-flex align-items-center justify-content-center">
              <Col md={3}>
                <div className="img-wrapper">
                  <img
                    src="/images/platey-on-cell.jpg"
                    alt="Doptelet Platelet Character on Cell Phone"
                  />
                </div>
              </Col>
              <Col md={5}>
                <h2>
                  Need financial assistance <br className="d-none d-md-block"></br> for Doptelet?
                </h2>
                <p>
                  Please call{" "}
                  <a
                    href="tel:+18333682663"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={pushContactClick}
                  >
                    <strong className="underline">
                      1&#45;833&#45;368&#45;2663
                    </strong>
                  </a>
                  . Care Coordinators are available Monday–Friday 8
                  <small>AM</small>–8
                  <small>PM</small> ET.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <ISICLDinline />
      </div>
      <FooterCLD />
      <ISIwrapper>
        <ISICLD />
      </ISIwrapper>
      <BackToTopBtn />
    </div >
  );
}

export default CLDHome;
