import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";

// Import Components
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import Accordion from "../../components/shared-ui/Accordion/Accordion";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";

import "../../css/AboutDoptelet.scss";

//SEO
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

function showTermsModal(event) {
  event.preventDefault();
  let modal = document.getElementById('terms-modal');
  document.body.classList.add("no-scroll");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100vh";
  if (event.length && event.currentTarget.classList.contains('scroll-to-terms')) {
    const terms = document.getElementById('terms');
    const container = document.querySelector('#terms-modal .modal-container');
    container.scroll(0, terms.offsetTop)
  }
}

function AboutDoptelet() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "What is DOPTELET® (avatrombopag) for ITP & CLD in Adults",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                "name": "Doptelet",
                "alternateName": "avatrombopag",
                "manufacturer": {
                  "@type": "Organization",
                  "name": "Sobi"
                },
                "dosageForm": "Pill"
              },
              {
                "@type": "WebSite",
                "about": {
                  "@type": "Thing",
                  "description": "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure."
                },
                "url": "https://doptelethcp.com/itp/what-is-doptelet/"
              },
              {
                "@type": "Brand",
                "logo": "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg"
              },
              {
                "@type": "Brand",
                "logo": "https://doptelet.com/images/sobi-logo.png"
              },
              {
                "url": "https://doptelet.com/what-is-doptelet/",
                "@type": "Substance",
                "name": "Avatrombopag",
                "description": "Avatrombopag is the active substance found in Doptelet, a medication used for the treatment of chronic immune thrombocytopenia (ITP) in certain adult patients."
              },
              {
                "@type": "MedicalStudy",
                "url": "https://doptelet.com/what-is-doptelet/",
                "name": "Clinical Trials for Doptelet",
                "description": "Explore the clinical trials conducted on Doptelet (avatrombopag) for the treatment of chronic immune thrombocytopenia (ITP) in adults.",
                "studyLocation": [
                  {
                    "@type": "AdministrativeArea",
                    "name": "United States"
                  }
                ],
                "sponsor": {
                  "@type": "Organization",
                  "name": "Dova Pharmaceuticals"
                }
              },
              {
                "@type": "MedicalIndication",
                "url": "https://doptelet.com/what-is-doptelet/",
                "name": "Chronic Immune Thrombocytopenia (ITP) Treatment",
                "description": "Doptelet is indicated for the treatment of chronic immune thrombocytopenia (ITP) in certain adult patients.",
                "medicineSystem": "http://purl.bioontology.org/ontology/RXNORM"
              },
              {
                "@type": "MedicalGuideline",
                "url": "https://doptelet.com/what-is-doptelet/",
                "name": "Chronic Immune Thrombocytopenia (ITP) Treatment Guidelines",
                "description": "Explore the treatment guidelines for chronic immune thrombocytopenia (ITP) and learn about Doptelet as an approved medication for certain adult patients.",
                "guidelineSubject": {
                  "@type": "MedicalEntity",
                  "name": "Chronic immune thrombocytopenia (ITP)"
                },
                "evidenceLevel": "Level B",
                "evidenceOrigin": "Clinical trials and expert consensus",
                "guidelineDate": "2022-01-01"
              },
              {
                "@type": "MedicalWebPage",
                "url": "https://doptelet.com/what-is-doptelet/",
                "name": "What is Doptelet?",
                "description": "Learn about Doptelet (avatrombopag), a medication approved for the treatment of chronic immune thrombocytopenia (ITP) in certain adult patients."
              },
              {
                "@type": "MedicalTherapy",
                "url": "https://doptelet.com/what-is-doptelet/",
                "name": "Doptelet Usage",
                "description": "Learn about the usage of Doptelet (avatrombopag) for the treatment of chronic immune thrombocytopenia (ITP) in certain adult patients.",
                "adverseOutcome": "Common side effects may include nausea, fatigue, headache, and muscle or joint pain. Contact your healthcare provider if you experience severe or persistent adverse reactions."
              },
              {
                "@type": "DrugClass",
                "url": "https://doptelet.com/what-is-doptelet/",
                "name": "Thrombopoietin Receptor Agonists",
                "description": "Thrombopoietin receptor agonists are a class of medications that stimulate the production and differentiation of megakaryocytes, leading to increased platelet production.",
                "drug": {
                  "@type": "Drug",
                  "name": "Doptelet"
                }
              }
            ]
          }),
        ]}
      >
        <title>About Doptelet: A Chronic ITP Treatment Medication | DOPTELET® (avatrombopag)</title>
        <meta
          name="description"
          content="Living with chronic ITP? Learn about different treatment options and medications like Doptelet, a thrombopoietin receptor agonist (TPO-RA) that can help."
        />
        <link
          rel="canonical"
          href="https://doptelet.com/what-is-doptelet/"
        />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap about-doptelet">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          markup={
            <>
              <h1>ABOUT DOPTELET AND THE DOPTELET LIFT:</h1>
              <p className="mb-0">Doptelet is a TPO-RA that helps lift your platelet counts without having to change some of your personal preferences. That means with Doptelet, there are no doctor's appointments for treatment injections, no food-type restrictions, and more.{' '}
                <a href="#side-effects">Wondering about possible side effects on Doptelet?</a></p>
            </>
          }
          maxWidth="515"
          bgImage={['about-doptelet-hero.jpg', 'about-doptelet-hero.jpg']}
          bgImageMobile={['about-doptelet-hero-mobile.jpg', 'about-doptelet-hero-mobile@2x.jpg']}
        />

        <div className="pt-5">
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 12 }}>
                <div>

                  <div>
                    <h2>Reach new heights with a platelet count lift that lasts</h2>
                    <h3 className="mb-5"><strong>In the 6-month clinical trial, Doptelet was shown to:</strong></h3>
                    <Row>
                      <Col>
                        <div className="d-flex align-items-center flex-column flex-md-row mb-4">
                          <img src="/images/icon_8days.svg" alt="8 days Icon" className="mr-4 mb-4 mb-md-0" />
                          <div>
                            <p className="mb-0">Lift platelet counts in as few as <strong>8 days</strong>.</p>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex align-items-center flex-column flex-md-row mb-4">
                          <img src="/images/icon_50k.svg" alt="50k Icon" className="mr-4 mb-4 mb-md-0" />
                          <div>
                            <p className="mb-0">Increase platelet counts to <strong>50,000</strong> per microliter <strong>or more</strong> for 66% of patients in trial. In the follow-up study of the clinical trial data, the analysis indicated over half of these patients reached or exceeded platelet counts of <strong>100,000</strong>.</p>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex align-items-center flex-column flex-md-row mb-4">
                          <img src="/images/icon_12weeks.svg" alt="12 Weeks Icon" className="mr-4 mb-4 mb-md-0" />
                          <div>
                            <p className="mb-0">Maintain an increase in platelet counts for an average of <strong>12 total weeks</strong>.</p>
                          </div>
                        </div>
                      </Col>
                    </Row>

                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Accordion Start */}
        <div className="accordions chronic-itp pb-0 pt-3">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <Accordion
                  title="Real patients. Real results."
                  defaultActiveKey="0"
                  markup={
                    <ul>
                      <li>49 people were in a 6-month clinical trial for Doptelet (clinical trials for a rare disease usually have smaller sample sizes than non-rare disease trials)</li>
                      <li>During that time, people taking Doptelet were compared with people not taking Doptelet</li>
                      <li>In the 6-month trial, those taking Doptelet were able to keep platelet counts at 50,000 platelets per microliter or higher for an average of 12 total weeks vs 0 weeks for those not taking Doptelet</li>
                      <li>On Day 8 of the trial, 21 out of 32 people taking Doptelet reached at least 50,000 platelets per microliter or more. Those who were not taking Doptelet did not reach 50,000 platelets per microliter</li>
                      <li>A follow-up review of the data from the clinical trial showed that 26 out of 32 patients reached at least 100,000 platelets per microliter at some point during the 6-month trial</li>
                    </ul>
                  }
                />
              </Col>
            </Row>
          </Container>
        </div>
        {/* Accordion End */}
        <div>
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 12 }}>
                <picture>
                  <img src="/images/soft-serve.png" alt="Platey serving soft serve" className="d-block m-auto img-responsive soft-serve" />
                </picture>
                <h3 className="mt-5"><strong>Are you stressed about when to take your chronic ITP treatment?</strong></h3>
                <h3 className="mb-5">Doptelet works with your body to help you make more platelets with less compromise</h3>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={12}>
                <Row>
                  <div className="d-flex align-items-center mb-0 mb-md-4">
                    <img src="/images/icon_no_injections.svg" alt="8 days Icon" className="mr-4 mb-2 mb-md-0" />
                    <div>
                      <p className="mb-0"><strong>No treatment injections or infusions</strong></p>
                      <p className="mb-0 d-none d-md-block">Doptelet isn’t a steroid or an immunosuppressant—it’s a TPO-RA (thrombopoietin receptor agonist) pill that requires platelet monitoring and can be taken anywhere.</p>
                    </div>
                  </div>
                  <p className="mb-4 d-block d-md-none">Doptelet isn’t a steroid or an immunosuppressant—it’s a TPO-RA (thrombopoietin receptor agonist) pill that requires platelet monitoring and can be taken anywhere.</p>
                </Row>
                <Row>
                  <div className="d-flex align-items-center mb-0 mb-md-4">
                    <img src="/images/icon_clock.svg" alt=" Icon" className="mr-4 mb-2 mb-md-0" />
                    <div>
                      <p className="mb-0"><strong>Fits your schedule</strong></p>
                      <p className="mb-0 d-none d-md-block">As long as you take Doptelet at the same time each day, you can choose when to take Doptelet.</p>
                    </div>
                  </div>
                  <p className="mb-4 d-block d-md-none">As long as you take Doptelet at the same time each day, you can choose when to take Doptelet.</p>
                </Row>
                <Row>
                  <div className="d-flex align-items-center mb-0 mb-md-4">
                    <img src="/images/icon_no_food_restrictions.svg" alt=" Icon" className="mr-4 mb-2 mb-md-0" />
                    <div>
                      <p className="mb-0"><strong>No food-type restrictions</strong></p>
                      <p className="mb-0 d-none d-md-block">Unlike some other TPO-RAs, there are no limits on when you can have your favorite foods. #FoodRequired</p>
                    </div>
                  </div>
                  <p className="mb-4 d-block d-md-none">Unlike some other TPO-RAs, there are no limits on when you can have your favorite foods. #FoodRequired</p>
                </Row>
                <Row>
                  <div className="d-flex align-items-center mb-0 mb-md-4">
                    <img src="/images/icon_no_liver.svg" alt=" Icon" className="mr-4 mb-2 mb-md-0" />
                    <div>
                      <p className="mb-0"><strong>No liver monitoring </strong></p>
                      <p className="mb-0 d-none d-md-block">Doptelet doesn’t require additional liver-function monitoring because in clinical trials it was not shown to lead to liver damage (or “hepatotoxicity” in medical terms).</p>
                    </div>
                  </div>
                  <p className="mb-4 d-block d-md-none">Doptelet doesn’t require additional liver-function monitoring because in clinical trials it was not shown to lead to liver damage (or “hepatotoxicity” in medical terms).</p>
                </Row>
                <Row>
                  <div className="d-flex align-items-center mb-0 mb-md-5">
                    <img src="/images/icon_no_eye_exams.svg" alt=" Icon" className="mr-4 mb-2 mb-md-0" />
                    <div>
                      <p className="mb-0"><strong>No eye exams</strong></p>
                      <p className="mb-0 d-none d-md-block">Doptelet can be used in people with cataracts. No eye exams are required before starting and during treatment with Doptelet.</p>
                    </div>
                  </div>
                  <p className="mb-4 d-block d-md-none">Doptelet can be used in people with cataracts. No eye exams are required before starting and during treatment with Doptelet.</p>
                </Row>
                <Row>
                  <p>Before starting Doptelet, you’ll want to tell your doctor about all of your medical conditions, like if you’ve ever had a blood clot, are pregnant, or plan to be pregnant.</p>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="mt-4 mt-md-5 pt-2 cloud-blue-bg video-section">
          <Container>
            <Row>
              <Col lg={{ span: 7 }}>
                <h2>Dopteller Diaries: Being Me</h2>
                <p>With Doptelet, I can just be me.<br />– Tammy</p>
                <div className="mb-4 video-wrapper d-lg-none">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={["dopteller_diaries_being_me.jpg", "dopteller_diaries_being_me@2x.jpg"]}
                    imageMobile={["dopteller_diaries_being_me.jpg", "dopteller_diaries_being_me@2x.jpg"]}
                    alt="Play Video: Dopteller Diaries: Life After Diagnosis"
                  />
                </div>
                <PrimaryButton
                  title="MORE DOPTELET VIDEOS"
                  href="/support-and-resources/chronic-itp-videos/"
                  addClass="btn-transparent btn-arrow"
                />
              </Col>
              <Col lg={5} className="d-none d-lg-block">
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={["dopteller_diaries_being_me.jpg", "dopteller_diaries_being_me@2x.jpg"]}
                    imageMobile={["dopteller_diaries_being_me.jpg", "dopteller_diaries_being_me@2x.jpg"]}
                    alt="Play Video: Dopteller Diaries: Life After Diagnosis"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="my-md-5 pt-5">
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 12 }}>
                <h2>How do I take Doptelet?</h2>
                <p className="mb-md-5">Doptelet is a 20-mg pill that must be taken with food. Your doctor will decide how much Doptelet you need to take, and for how long, based on how your platelet count responds.</p>
                <PrimaryButton
                  title="Curious how to get doptelet?"
                  href="/support-and-resources/financial-assistance/"
                  addClass="btn-transparent btn-arrow larger"
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div id="side-effects" className="pt-5">
          <Container className="border-top pt-5">
            <Row className="align-items-center">
              <Col md={{ span: 12 }}>
                <img src="/images/platey_waving.png" className="d-block m-auto pt-5 pb-5" />
                <h2 className="mb-5">Like all medicines, there’s a chance you may experience side effects with Doptelet</h2>
                <h3 className="mb-2"><strong>Common side effects</strong></h3>
                <p className="mb-5">You should know that the most common side effects of Doptelet are headache, tiredness, bruising, nosebleed, upper respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin (petechiae), and runny nose.</p>
                <h3 className="mb-2"><strong>Serious side effects</strong></h3>
                <p className="mb-4">Doptelet is a TPO-RA, and TPO-RAs have been associated with blood clots. Tell your doctor right away if you have symptoms of a blood clot, including:</p>
                <ul className="mb-4 ml-1 ml-md-3">
                  <li className="mb-3">Swelling, pain, or tenderness in your legs</li>
                  <li className="mb-3">Shortness of breath</li>
                  <li className="mb-3">Chest pain</li>
                  <li className="mb-3">Fast heartbeat</li>
                  <li>Stomach (abdominal) pain or tenderness</li>
                </ul>
                <p><strong>These are not all the <a href="#isi-side-effects">possible side effects</a> of Doptelet. Talk to your doctor if any side effect bothers you or does not go away.</strong></p>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <div className="get-chronic-updates d-flex align-items-center flex-column flex-lg-row mt-5 mb-1 p-5 cloud-blue-bg">
                  <picture>
                    <img src="/images/Platey_Ryder_Bubbles.png" alt="Doptelet Platelet Character Blowing Bubbles" className="mr-4 d-lg-none d-sm-inline-block free-hugs" />
                  </picture>
                  <div className="col-lg-8">
                    <h2>Get chronic ITP updates</h2>
                    <p>Sign up to access tips and tools for managing ITP—plus, a surprise shipped to your doorstep!</p>
                    <PrimaryButton
                      title="GET CHRONIC ITP TIPS"
                      href="/support-and-resources/living-with-itp/"
                      addClass="btn-arrow btn-transparent"
                    />
                  </div>
                  <picture>
                    <img src="/images/Platey_Ryder_Bubbles.png" alt="Doptelet Platelet Character Blowing Bubbles" className="mr-4 d-none d-lg-block" />
                  </picture>
                </div>
              </Col>
            </Row>

          </Container>
        </section>

        <div className="mt-0 mt-md-5 bottom-page-ctas">
          <Container fluid>
            <Row>
              <Col md={6} className="d-flex flex-column">
                <div className="content">
                  <img src="/images/platey_holding_balloons.png" />
                  <h2>Got questions? Speak to a Doptelet Advisor</h2>
                  <p className="flex-grow-1">Trying to raise your low platelet count can raise some questions. The good news is, our Doptelet Advisors can help get you the answers you need and point you toward resources. Give them a call at <a href="tel:+8554543887" className="text-nowrap"><strong>855-4-LIFTUP</strong> (1-855-454-3887)</a>.</p>
                </div>
              </Col>
              <Col md={6} className="cloud-blue-bg d-flex flex-column">
                <div className="content">
                  <img src="/images/platey_tossing_kid_2.png" />
                  <h2>Considering Doptelet?</h2>
                  <p className="flex-grow-1">Find out what makes Doptelet different than other chronic ITP treatments!</p>
                  <PrimaryButton
                    title="CONSIDERING DOPTELET?"
                    href="/what-is-doptelet/why-doptelet/"
                    addClass="btn-arrow align-self-start"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default AboutDoptelet;
