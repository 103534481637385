import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import {
  pushOutboundClick
} from "../../assets/datalayerFunctions";

// Import Components
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import Accordion from "../../components/shared-ui/Accordion/Accordion";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";
// Import Styles
import "../../css/DiagnosisAndTreatment.scss";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";
import { Link } from "react-router-dom";

function showModal(url) {
  console.log(url)
  let modal = document.querySelector("#modal");
  document.body.classList.add("no-scroll", "modal-open");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100%";
  modal.children[0].children[1].children[3].href = url;
  modal.children[0].children[1].children[3].target = "_blank";
  modal.children[0].children[1].children[3].addEventListener(
    "click",
    function() {
      modal.style.display = "none";
    }
  );
}

function DiagnosisAndTreatment() {
  function addModalLinks(event) {
    console.log(event.target)
    pushOutboundClick(event);
    event.preventDefault();
    let url = event.currentTarget.href;
    let title = event.currentTarget.title;
    showModal(url, title);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Diagnosis & Treatment | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);

  // Simulate clicking..this is dirt, im sorry!
  async function simulateMouseClick(el) {
    let opts = { view: window, bubbles: true, cancelable: true, buttons: 1 };
    el.dispatchEvent(new MouseEvent("mousedown", opts));
    await new Promise(r => setTimeout(r, 50));
    el.dispatchEvent(new MouseEvent("mouseup", opts));
    el.dispatchEvent(new MouseEvent("click", opts));
  }

  const whatTPORA = (event) => {
    const tpora = document.getElementById("what-tpo-ra").getElementsByClassName("btn-link");
    simulateMouseClick(tpora[0]);
  };

  const howITP = (event) => {
    const tpora = document.getElementById("how-itp-diagnosed").getElementsByClassName("btn-link");
    simulateMouseClick(tpora[0]);
  };

  return (
    <div className="main_content_wrap">
      <Helmet>
        <title>Immune Thrombocytopenia (ITP) Diagnosis & Treatment | DOPTELET® (avatrombopag)</title>
        <meta
          name="description"
          content="Learn about how ITP is diagnosed, what your doctor may look for, platelet count ranges, and various treatment options like splenectomy, TPO-RAs, and more."
        />
        <link rel="canonical" href="https://doptelet.com/what-is-itp/itp-diagnosis-and-treatment/" />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          markup={
            <>
              <h1>ITP DIAGNOSIS &amp; TREATMENT</h1>
              <p className="mb-0">There’s no specific way to test for ITP—it’s a “diagnosis of exclusion.” First, your doctor will try to rule out other possible causes of low platelets, like an underlying illness you may have or any medication you may be taking. If no other cause is found, your doctor may diagnose you with ITP.{" "}
                <a href="#how-itp-diagnosed"
                  onClick={howITP}>Curious what types of test your doctor might run?</a>
              </p>
            </>
          }
          maxWidth="500"
          bgImage={['diagnosis-treatment-hero.jpg', 'diagnosis-treatment-hero.jpg']}
          bgImageMobile={['diagnosis-treatment-hero-mobile.jpg', 'diagnosis-treatment-hero-mobile@2x.jpg']}
        />

        <div className="pt-5">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 12 }}>
                <h2>How is ITP treated in adults?</h2>
                <p>Steroids are normally the first step in treatment, but they can have unwanted side effects, like an increased risk of infections, diabetes, and cataracts.</p>
                <p className="orange-box text-orange"><strong>That is one of the reasons the current American Society of Hematology (ASH) guidelines recommend limiting steroid treatment up to 6 weeks.</strong></p>
                <p>If steroids aren’t working well enough after 6 weeks, or if your first couple of treatments were unsuccessful, your doctor may consider trying immunosuppressants, spleen removal surgery (splenectomy), or a treatment that can help increase your platelet count like Doptelet.</p>
                <p>By the way, immunosuppressants are a type of medication used to dampen your body’s immune response.</p>
                <p>However, Doptelet isn’t a steroid and doesn’t suppress your immune system—it’s a <a href="#what-tpo-ra" onClick={whatTPORA}>thrombopoietin receptor agonist</a> (TPO-RA).</p>
                <p>A TPO-RA is a medication that mimics your body’s natural process of creating platelets. It boosts the pathway responsible for making platelets, which adds to the platelets your body is already making. This ultimately increases your platelet count.</p>
                <p>Unlike other TPO-RAs, Doptelet doesn’t require weekly doctor appointments for treatment injections or restrict you from your favorite foods, like ice cream.</p>
                <p>On Doptelet, work with your doctor to determine a dosing schedule that works best for you and you can take it no matter where you are. Just be sure to take it with food and at the same time each day.</p>
                <p>Before starting Doptelet, you’ll want to tell your doctor about all of your medical conditions, like if you’ve ever had a blood clot, are pregnant, or plan to become pregnant.</p>
              </Col>
            </Row>
          </Container>
        </div>


        <div className="mt-5 pt-2 cloud-blue-bg video-section dopteller-diaries">
          <Container>
            <Row>
              <Col lg={{ span: 7 }}>
                <h2 className="mb-4">Tammy’s Tips: A Doctor’s Guide to Exploring Treatment Options</h2>
                <div className="video-wrapper d-lg-none">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={["tammystips-a-doctors-guide.jpg", "tammystips-a-doctors-guide.jpg"]}
                    imageMobile={["tammystips-a-doctors-guide.jpg", "tammystips-a-doctors-guide.jpg"]}
                    alt="Play Video: Dopteller Diaries: Life After Diagnosis"
                  />
                </div>
                <p>Hear a real doctor, Dr Van Doren, and a real patient, Tammy,<br className="d-none d-xl-block"></br> discuss treatment options for chronic ITP.<br />Dr Van Doren is not Tammy’s doctor.</p>
                <div className="d-flex">
                  <PrimaryButton
                    title="MORE DOPTELET VIDEOS"
                    href="/support-and-resources/chronic-itp-videos/"
                    addClass="mt-md-5 btn-transparent btn-arrow"
                  />
                </div>
              </Col>
              <Col lg={5} className="d-none d-lg-block">
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={["tammystips-a-doctors-guide.jpg", "tammystips-a-doctors-guide.jpg"]}
                    imageMobile={["tammystips-a-doctors-guide.jpg", "tammystips-a-doctors-guide.jpg"]}
                    alt="Play Video: Dopteller Diaries: Life After Diagnosis"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="pt-5">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 12 }}>
                <h2>DIAGNOSIS &amp; TREATMENT QUESTIONS</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="accordions mt-0 pb-0 pt-3">
          <Container>
            <Row>
              <Col lg={{ span: 12 }}>
                <Accordion
                  title="How is ITP diagnosed?"
                  id="how-itp-diagnosed"
                  className="scroll-to-section"
                  markup={
                    <>
                      <p>Wondering what types of tests your doctor will run? Here are a few common ones:</p>
                      <ul>
                        <li>Complete blood count (CBC): your doctor will draw a sample of blood and use it to measure your number of blood cells, including your platelet counts</li>
                        <li>Blood smear: your doctor will smear a thin layer of your blood—normally taken as part of or along with a CBC—onto a glass slide and look at it under a microscope to find out more about the number and shape of your blood cells</li>
                        <li>Bone marrow exam: if the other blood tests show that your platelet count is low, your doctor may want to collect a sample of your bone marrow (they’ll numb the area beforehand) to make sure it’s making enough platelets</li>
                      </ul>
                    </>
                  }
                />
                <Accordion
                  title="What is a TPO-RA?"
                  id="what-tpo-ra"
                  bodyText="Your body has a natural signal—a hormone called “thrombopoietin” (TPO)—that tells it to make enough platelets for you. TPO-RA medications bind to and activate the thrombopoietin receptor, which helps stimulate the production of platelets in the body. When your body isn’t producing enough natural TPO to make the platelets you need, your doctor may prescribe a TPO-RA to help lift your platelet count."
                  className="scroll-to-section"
                />
                <Accordion
                  title="What is the difference between newly diagnosed and chronic ITP?"
                  bodyText="Low platelet counts that last less than 3 months are considered “newly diagnosed” ITP. If they last for more than a year, it’s called “chronic” or long-term ITP. Newly diagnosed ITP is more common in children, while chronic ITP mostly affects young adult women."
                  bodyText2="If you experience low platelet counts that last between 3-12 months, it’s called “persistent ITP.”"
                  className="scroll-to-section"
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="pt-5">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 12 }}>
                <h2>KEEP YOUR ITP JOURNEY ON TRACK</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="pt-0 pt-md-4">
          <Container>
            <Row className="align-items-center mb-3">
              <Col lg={{ span: 12 }} className="cloud-blue-bg p-4 p-md-5 florio-section">
                <div className="d-flex">
                  <img src="/images/MyFlorioLogo.jpg" />
                  <div>
                    <h2>my florio ITP&trade;</h2>
                    <p>my florio ITP&trade; is an all-in-one app designed to monitor all things ITP—platelet counts, bruising events, appointments, treatments, and more—plus, it’s free!</p>
                    <p>
                      <a href="https://apps.apple.com/no/app/florio-itp/id1610121401" onClick={addModalLinks} target="_blank" className="app-link">
                        <img src="/images/app-store.png" /></a>
                      <a href="https://play.google.com/store/apps/details?id=app.florio.itp.eu.mdd&pli=1" onClick={addModalLinks} target="_blank" className="app-link"><img src="/images/google-play.png" /></a>
                    </p>
                    <p>
                      <a href="http://doptelet.com/themes/pdf/florio-itp-app-one-pager.pdf" className="download-link" target="_blank"><img src="/images/icon_download.svg" /> DOWNLOAD BROCHURE</a>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 pl-0">
                <PrimaryButton
                  title="Want to see more chronic ITP Resources?"
                  href="/support-and-resources/itp-patient-resources/"
                  addClass="btn-transparent btn-arrow align-self-start itp-resources"
                />
              </Col>
            </Row>
          </Container>
        </div>


        <div className="mt-4 pt-1 bottom-page-ctas">
          <Container fluid>
            <Row>
              <Col md={6} className="d-flex flex-column">
                <div className="content">
                  <img src="/images/Platey_Ryder_Bubbles.png" />
                  <h2>Considering Doptelet for your chronic ITP treatment?</h2>
                  <p className="flex-grow-1">Learn more about Doptelet versus other treatment options.</p>
                  <PrimaryButton
                    title="CONSIDERING DOPTELET?"
                    href="/what-is-doptelet/why-doptelet/"
                    addClass="btn-transparent btn-arrow align-self-start"
                  />
                </div>
              </Col>
              <Col md={6} className="cloud-blue-bg d-flex flex-column">
                <div className="content">
                  <img src="/images/Platey_Taking_Picture.png" />
                  <h2>Learn more about Doptelet and how it works</h2>
                  <p className="flex-grow-1">Discover how Doptelet might help lift your platelet counts and meet your treatment needs for chronic ITP.</p>
                  <PrimaryButton
                    title="ABOUT DOPTELET"
                    href="/what-is-doptelet/"
                    addClass="btn-arrow align-self-start"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default DiagnosisAndTreatment;
