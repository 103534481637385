import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

// Import Components
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import Accordion from "../../components/shared-ui/Accordion/Accordion";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

function SignsAndSymptoms() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Signs & Symptoms | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);

  return (
    <div className="main_content_wrap">
      <Helmet>
        <title>Signs & Symptoms of Immune Thrombocytopenia (ITP) | DOPTELET® (avatrombopag)</title>
        <meta
          name="description"
          content="Learn about chronic immune thrombocytopenia (ITP) signs and symptoms like fatigue, low platelets and bruising, and how DOPTELET® can help."
        />
        <link rel="canonical" href="https://doptelet.com/what-is-itp/itp-signs-and-symptoms/" />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          markup={
            <>
              <h1>Signs &amp; Symptoms OF Immune Thrombocytopenia (ITP)</h1>
              <p className="mb-0">Let’s talk about the signs and symptoms of chronic ITP.</p>
            </>
          }
          maxWidth="480"
          bgImage={['signs-symptoms-hero.jpg', 'signs-symptoms-hero.jpg']}
          bgImageMobile={['signs-symptoms-hero-mobile.jpg', 'signs-symptoms-hero-mobile@2x.jpg']}
        />

        <div className="pt-5 pb-5 symptoms-itp ">
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 12 }}>
                <div>
                  <h2>What are the common signs and symptoms of ITP?</h2>
                  <p>A sign is something others (ex: your care team) can observe or measure, but symptoms are the feelings and concerns that you experience.</p>
                  <p>With ITP, your platelet count is usually less than 100,000 per microliter. The lower your platelet count, the greater the risk of ITP symptoms.</p>
                  <Row className="mt-5">
                    <Col>
                      <div className="d-flex align-items-center mb-0 mb-md-4">
                        <img src="/images/icon_bruising.svg" alt="Bruising Symptom Icon" className="mr-4 mb-2 mb-md-0" />
                        <div>
                          <p className="mb-0"><strong>Bruising</strong></p>
                          <p className="mb-0 d-none d-md-block">You’ll notice your skin start to bruise very easily, even spontaneously, from damaged blood vessels under the skin.</p>
                        </div>
                      </div>
                      <p className="mb-4 d-block d-md-none">You’ll notice your skin start to bruise very easily, even spontaneously, from damaged blood vessels under the skin.</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="d-flex align-items-center mb-0 mb-md-4">
                        <img src="/images/icon_petechiae.svg" alt="Petechiae or Purpura on Hand Icon" className="mr-4 mb-2 mb-md-0" />
                        <div>
                          <p className="mb-0"><strong>Petechiae/Purpura</strong></p>
                          <p className="mb-0 d-none d-md-block">You’ll see small red or purple spots start to appear from broken blood vessels on areas like your lower legs.</p>
                        </div>
                      </div>
                      <p className="mb-4 d-block d-md-none">You’ll see small red or purple spots start to appear from broken blood vessels on areas like your lower legs.</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="d-flex align-items-center mb-0 mb-md-4">
                        <img src="/images/icon_bleeding.svg" alt="Multiple Droplets of Blood Icon" className="mr-4 mb-2 mb-md-0" />
                        <div>
                          <p className="mb-0"><strong>ITP Bleeding</strong></p>
                          <p className="mb-0 d-none d-md-block">You may experience bleeding from your gums and nose, have an unusually heavy menstrual flow, or notice cuts bleeding for longer periods of time. You may also see blood in your urine, vomit, or stool.</p>
                        </div>
                      </div>
                      <p className="mb-4 d-block d-md-none">You may experience bleeding from your gums and nose, have an unusually heavy menstrual flow, or notice cuts bleeding for longer periods of time. You may also see blood in your urine, vomit, or stool.</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="d-flex align-items-center mb-0 mb-md-2">
                        <img src="/images/icon_fatigue.svg" alt="Low Energy Battery Icon" className="mr-4 mb-2 mb-md-0" />
                        <div>
                          <p className="mb-0"><strong>Fatigue</strong></p>
                          <p className="mb-0 d-none d-md-block">You may feel tired and weak.</p>
                        </div>
                      </div>
                      <p className="mb-0 d-block d-md-none">You may feel tired and weak.</p>
                    </Col>
                  </Row>

                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="cloud-blue-bg dopteller-diaries video-section">
          <Container>
            <Row>
              <Col lg={{ span: 7 }}>
                <h2 className="mb-4">Tammy’s Tips: Things I Wish I Knew When I Was Diagnosed With Chronic ITP</h2>
                <div className="video-wrapper d-lg-none">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={["tammystips-things-i-wish-i-knew.jpg", "tammystips-things-i-wish-i-knew.jpg"]}
                    imageMobile={["tammystips-things-i-wish-i-knew.jpg", "tammystips-things-i-wish-i-knew.jpg"]}
                    alt="Play Video: Dopteller Diaries: Life After Diagnosis"
                  />
                </div>

                <p className="mb-lg-5">Tammy, a real patient on Doptelet, has learned a thing or two from her experience with chronic ITP and wants to share her tips with you.</p>
                <PrimaryButton
                  title="MORE DOPTELET VIDEOS"
                  href="/support-and-resources/chronic-itp-videos/"
                  addClass="btn-transparent btn-arrow"
                />
              </Col>
              <Col lg={5} className="d-none d-lg-block">
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={["tammystips-things-i-wish-i-knew.jpg", "tammystips-things-i-wish-i-knew.jpg"]}
                    imageMobile={["tammystips-things-i-wish-i-knew.jpg", "tammystips-things-i-wish-i-knew.jpg"]}
                    alt="Play Video: Dopteller Diaries: Life After Diagnosis"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>


        <div className="pt-5 symptoms-itp">
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 12 }}>
                <div>
                  <h2>SIGNS &amp; SYMPTOMS QUESTIONS</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Accordion Start */}
        <div className="accordions chronic-itp pb-0 pt-3 my-0">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <Accordion
                  title="Can ITP cause dizziness?"
                  bodyText="With immune thrombocytopenia, you may not have any symptoms. But symptoms may include petechiae/purpura, bruises, bleeding gums, blood in stool, blood in urine, heavy menstrual periods, heavy nosebleeds, and fatigue."
                  className="mb-0"
                />
              </Col>
            </Row>
          </Container>
        </div>
        {/* Accordion End */}

        <div>
          <Container className="pt-5">
            <Row>
              <Col md={{ span: 12 }}>
                <h2>Have more questions?</h2>
                <p>Answers to your questions about Doptelet are just a click away!</p>
                <PrimaryButton
                  title="VIEW FAQ"
                  href="/chronic-itp-faqs/"
                  addClass="btn-transparent btn-arrow"
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="mt-5 bottom-page-ctas">
          <Container fluid>
            <Row>
              <Col md={6} className="d-flex flex-column">
                <div className="content">
                  <img src="/images/Plateys_Walking.png" />
                  <h2>Want more information about chronic ITP?</h2>
                  <p className="flex-grow-1">Sign up to access tips and tools for managing ITP!</p>
                  <PrimaryButton
                    title="SIGN ME UP"
                    href="/support-and-resources/living-with-itp/"
                    addClass="btn-transparent btn-arrow align-self-start"
                  />
                </div>
              </Col>
              <Col md={6} className="cloud-blue-bg d-flex flex-column">
                <div className="content">
                  <img src="/images/Platey_Basketball.png" />
                  <h2>It’s important to know your options when treating your ITP</h2>
                  <p className="flex-grow-1">Discover how the diagnosis and treatment process of ITP works.</p>
                  <PrimaryButton
                    title="DIAGNOSIS &amp; TREATMENT"
                    href="/what-is-itp/itp-diagnosis-and-treatment/"
                    addClass="btn-arrow align-self-start"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default SignsAndSymptoms;
