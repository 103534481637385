import React, { useEffect } from "react";
import { helmetJsonLdProp } from "react-schemaorg";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import Helmet from "react-helmet";
import { pushContactClick } from "../../assets/datalayerFunctions";

// Import Components
import TextOnlyHeroHeader from "../../components/HeroHeader/TextOnlyHeroHeader";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";
import VideoLibrary from "../../components/VideoLibrary";

function VideoResources() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "ITP Video Resources | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/doptelet-copay/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
              {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                      "@id": "https://doptelet.com/support-and-resources/chronic-itp-videos/",
                      "Name": "Find ITP Resources"
                    }
                  }
                ]
              },
            ],
          }),
        ]}
      >
        <title>Chronic ITP Video Resources | DOPTELET® (avatrombopag)</title>
        <meta
          name="description"
          content="Are you a patient or a caregiver of someone living with chronic ITP? Access our video resources, hear other patients’ experiences & learn more."
        />
        <link rel="canonical" href="https://doptelet.com/support-and-resources/chronic-itp-videos/" />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap financial">
        <TextOnlyHeroHeader
          markup={
            <>
              <h1>ITP VIDEO RESOURCES</h1>
              <p>Have you met Tammy, a Doptelet Ambassador? Watch her talk about life after diagnosis, reaching her goals, why Doptelet works for her, and more.</p>
              <p>Looking to share your own story? We&lsquo;d love to hear from you!</p>
              <p><a href="#share-your-story">Scroll down for all the details</a>.</p>
            </>
          }
        />

        <section className="pt-5 pb-0 pb-md-5" id="videos">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <VideoLibrary />
              </Col>
            </Row>
          </Container>
        </section>

        <div className="mt-0 pt-0 mt-md-5 pt-md-5 bottom-page-ctas">
          <Container fluid>
            <Row>
              <Col md={6} className="d-flex flex-column scroll-to-section" id="share-your-story">
                <div className="content">
                  <picture className="text-center">
                    <img src="/images/platey-tossing-kid-3.png" />
                  </picture>
                  <h2>Want to share your own story?</h2>
                  <p className="flex-grow-1">We're searching for our next ambassador to inform and inspire others living with chronic immune thrombocytopenia by sharing their experiences on Doptelet.</p>
                  <PrimaryButton
                    title="SHARE YOUR STORY"
                    href="/support-and-resources/share-your-story/"
                    addClass="btn-transparent btn-arrow align-self-start"
                  />
                </div>
              </Col>
              <Col md={6} className="cloud-blue-bg d-flex flex-column">
                <div className="content">
                  <picture className="text-center">
                    <source
                      srcSet="/images/Platey_Ryder_Bubbles.png 1x, /images/Platey_Ryder_Bubbles@2x.png 2x"
                      type="image/png" />
                    <img src="/images/Platey_Ryder_Bubbles.png" />
                  </picture>
                  <h2>Do you want updates on tips for managing chronic ITP?</h2>
                  <p className="flex-grow-1">Sign up to access tips and tools for managing ITP&ndash;plus, a surprise shipped to your doorstep!</p>
                  <PrimaryButton
                    title="GET CHRONIC ITP TIPS"
                    href="/support-and-resources/living-with-itp/"
                    addClass="btn-arrow align-self-start"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default VideoResources;
