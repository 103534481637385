import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

import { Container, Row, Col } from "react-bootstrap/esm/index";

// Import Components
import TextOnlyHeroHeader from "../../components/HeroHeader/TextOnlyHeroHeader";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

// Import Styles
import "../../css/GetGoalGettersTips.scss";

function GetGoalGettersTips() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Doptelet® Goal Getters Sign Up | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "MedicalTherapy",
                seriousAdverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "DOPTELET may cause serious side effects, including blood clots. People with chronic immune thrombocytopenia and people with certain blood clotting conditions may have an increased risk of developing blood clots. Tell your healthcare provider right away if you have signs and symptoms of a blood clot, including:Swelling, pain, or tenderness in your legs, Shortness of breath, Chest pain, Fast heartbeat, and Stomach (abdominal) pain or tenderness.",
                },
              },
              {
                "@type": "MedicalTherapy",
                adverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "'The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, and purple or red spots on your skin.",
                },
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
              {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://doptelet.com/support-and-resources/living-with-itp/",
                      "Name": "ITP"
                    }
                  }
                ]
              },
            ],
          }),
        ]}
      >
        <title>Updates & Tips for Living with Chronic ITP | DOPTELET® (avatrombopag)</title>
        <meta
          name="description"
          content="Sign up to receive access to information about Doptelet®, tips for living with and managing chronic immune thrombocytopenia (ITP), and more."
        />
        <link rel="canonical" href="https://doptelet.com/support-and-resources/living-with-itp/" />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap goal-getters">
        <TextOnlyHeroHeader
          markup={
            <>
              <h1>Get tips for living with ITP</h1>
              <p>Welcome! Are you, or is someone you know, living with chronic ITP and looking for resources and treatment tips? You’ve come to the right place!</p>
              <p>Community members can count on access to Doptelet and chronic ITP-related tips that aim to help them set and reach their treatment goals. If you're looking for a resource to help set your treatment goals, look no further.</p>
            </>
          }
        />

        <section className="doptelet-goal-getters-signup pt-5">
          <Container>
            <Row className="align-items-center">
              <Col>
                <h2>Join the Doptelet community!</h2>
                <p className="pr-md-3">Once you sign up, you’ll be entered into our system and can expect a Welcome Kit to start making its way to you.</p>
              </Col>
              <Col className="ml-auto">
                <img src="/images/welcome-kit-image.png" alt="" className="m-auto d-block img-responsive" style={{ maxHeight: 380 }} />
              </Col>
            </Row>
            <Row className="">
              <Col>
                <div className="signup-form">
                  <SignUpForm />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="mt-md-5 pt-md-5 bottom-page-ctas">
          <Container fluid>
            <Row>
              <Col md={6} className="d-flex flex-column">
                <div className="content">
                  <picture className="text-center">
                    <img src="/images/Platey_Ryder_Bubbles.png" alt="Platey" />
                  </picture>
                  <h2>Looking for more chronic ITP support?</h2>
                  <p className="flex-grow-1 pr-xl-3">Informational downloads, support organizations, and more are waiting for you.</p>
                  <PrimaryButton
                    title="This Way"
                    href="/support-and-resources/itp-patient-resources/"
                    addClass="btn-transparent btn-arrow align-self-start"
                  />
                </div>
              </Col>
              <Col md={6} className="cloud-blue-bg d-flex flex-column">
                <div className="content">
                  <picture className="text-center">
                    <img src="/images/platey-tossing-kid-3.png" alt="Platey Kid" />
                  </picture>
                  <h2>Have a chronic ITP story?</h2>
                  <p className="flex-grow-1">If you're currently being treated with Doptelet, join the ambassador program and share your story to help others like you!</p>
                  <PrimaryButton
                    title="Share Your Story"
                    href="/support-and-resources/share-your-story/"
                    addClass="btn-arrow align-self-start"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default GetGoalGettersTips;
