import React from "react";
import { Container } from "react-bootstrap";

import "../../css/HeroHeader.scss";

function ITPHeroHeader(props) {
  const desktopImg = props.bgImage[0];
  const desktopRetinaImg = props.bgImage[1];
  const mobileImg = props.bgImageMobile[0];
  const mobileRetinaImg = props.bgImageMobile[1];
  let css = `
    .hero_header {
      background-image: image-set(
        url(/images/${desktopImg}) 1x,
        url(/images/${desktopRetinaImg}) 2x
      );
    }
    @media screen and (max-width: 991px) {
      .hero_header {
        background-image: image-set(
          url(/images/${mobileImg}) 1x,
          url(/images/${mobileRetinaImg}) 2x
        );
      }
    }
  `;
  if (!props.textSitsOnImageForMobile) {
    css += `
      @media screen and (max-width: 991px) {
        .hero_header {
          background-image: none !important;
        }
        .hero_header_inner {
          padding-top: 0;
        }
      }
    `;
  }
  else {
    css += `
      @media screen and (max-width: 991px) {
        .hero_header_inner {
          background: transparent !important;
          color: white;
          min-height: 790px;
        }
      }
    `;
  }
  return (
    <div className={`hero_header ${props.extraClass}`}>
      <style>{css}</style>
      <Container>
        <div className="hero_header_inner" style={{
          maxWidth: props.maxWidth ? props.maxWidth + 'px' : ''
        }}>
          {props.markup}
        </div>
      </Container>
      {props.textSitsOnImageForMobile ? '' :
        <div className="hero-img-mobile d-lg-none">
          <picture>
            <source
              srcSet={'/images/' + mobileImg + ' 1x, /images/' + mobileRetinaImg + ' 2x'} />
            <img src={'/images/' + mobileImg} className="img-responsive" />
          </picture>
        </div>
      }
    </div>
  );
}

export default ITPHeroHeader;
